/* ------------------------------------------------------------ *\
	Intro
\* ------------------------------------------------------------ */

.intro {
    padding: 154px 0 130px;
    min-height: 774px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    font-family: $font_third;
    position: relative;

    & .intro__inner {
        z-index: 10;
        position: relative;
    }

    & .intro__title {
        font-size: 114px;
        font-weight: 700;
        margin-bottom: 25px;
    }

    & .intro__head {
        text-align: center;
        margin-bottom: 30px;
    }

    & .intro__subtitle {
        font-size: 43px;
        color: $blue;
        font-weight: 600;
    }

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba($white,.75);
        top: 0;
        left: 0;
    }
}

@media(max-width: 1023px) {
    .intro {
        min-height: 544px;
        padding: 64px 0 50px;
        
        .intro__title {
            font-size: 74px;
        }

        .intro__subtitle {
            font-size: 32px;
        }
    }
}

@media(max-width: 767px) {
    .intro {
        min-height: 544px;
        padding: 44px 0 40px;
        
        .intro__title {
            font-size: 48px;
            margin-bottom: 12px;
        }

        .intro__subtitle {
            font-size: 22px;
        }
    }
}

@media(max-width: 479px) {
    .intro {
        padding: 15px 0 20px;

        & .intro__head {
            margin-bottom: 15px;
        }
        
        .intro__title {
            font-size: 36px;
            margin-bottom: 7px;
            line-height: 1;
        }

        .intro__subtitle {
            font-size: 22px;
        }
    }
}

@media(max-width: 320px) {
    .intro {
        padding: 5px 0 20px;

        & .intro__head {
            margin-bottom: 10px;
        }
        
        .intro__title {
            font-size: 25px;
            margin-bottom: 6px;
        }

        .intro__subtitle {
            font-size: 14px;
        }
    }
}

// .intro {
//     position: relative;
//     z-index: 0;

// 	& .intro__inner {
// 		position: absolute;
// 		top: 90px;
// 		z-index: 1;
// 		left: 50%;
// 		transform: translateX(-50%);
		
// 		& .shell {
// 			width: 1170px;
// 		}

// 	}

// 	& .intro__callout {
// 		float: right;
// 		min-width: 460px;
// 		max-width: 460px;
// 		background: rgba(255, 255, 255, 0.6);
// 		text-align: center;
// 		padding: 47px 0 28px;
// 		text-transform: uppercase;
// 		color: $white;
// 		font-weight: 700;
// 		text-shadow: 2px 2px 2px #215781;
//         border: 1px solid #4ea3e6;
//         box-shadow: 0 0 10px rgba(34, 69, 96, 0.41);

// 		& small,
// 		& h3 {
// 			display: inline-block;
// 			color: $yellow;
// 		}

// 		& small {
// 			font-size: 40px;
// 		}

// 		& h3 {
// 			font-size: 86px;
// 		    font-weight: 700;
// 		    margin-bottom: 40px;
// 		}

// 		& h1 {
// 			font-size: 65px;
// 			margin: 0;
// 		}

// 		& h2 {
// 			font-size: 36px;
// 		}
// 	}

// 	& .intro__form {
// 		color: $white;
// 		min-width: 555px;
// 		max-width: 555px;
// 		float: left;
// 		background-size: cover;
// 		background-position: bottom center;
// 		padding-bottom: 24px;
// 		box-shadow: 1px 1px 14px rgba(34, 69, 96, 0.36);

// 		& .tooltip_alt {
// 			position: absolute;
// 			right: -20px;
// 			top: -3px;
// 		}
		
// 		& .form__head {
// 			text-align: center;
// 			padding: 25px 0;
// 			background: $blue;
// 			font-weight: 700;
//             text-transform: uppercase;
//             position: relative;

//             &:after {
//                 content: '';
//                 bottom: -9px;
//                 left: 50%;
//                 transform: translateX(-50%);
//                 position: absolute;
//                 background: #2994e6;
//                 height: 19px;
//                 width: 37px;
//                 border-bottom-left-radius: 90px;
//                 border-bottom-right-radius: 90px;
//             }
// 		}
        
// 		& .form__body {
// 			padding: 25px 30px; 

// 			& .form__row {
// 				display: flex;
// 				align-items: center;
// 			}

// 			& .form-group-2of2 {
// 				flex: 1 1 75%;
// 			    padding-right: 25px;
// 			}

// 			& .form__row {
// 				margin-bottom: 30px;
// 			}
// 		}

// 		& .form__foot {
// 			text-align: center;
// 		}
// 	}
// }

// @media (max-width: 1160px) {
//     .intro {
//         & .intro__inner  {
//             & .shell {
//                 width: 100%;
//             }
//         }

//         & .intro__form,
//         & .intro__callout {
//             float: none;
//             margin: auto;;
//         }

//         & .intro__callout {
//             display: none;
//         }
//     }
// }

// @media (max-width: 767px) {
//     .intro {
//     	& .intro__inner  {
// 			top: 6px;    	
//     	}

//         .intro__form {
//             min-width: 460px;
//             max-width: 100%;

//             & .form__body {
//                 & .form__row {
//                     flex-direction: column;
//                 }

//                 & .form-group-2of2 {
//                     padding-right: 0;
//                 }
//             }

//             & .form__head {
//             	padding: 25px 40px
//             }
//         }
//     }
// }

// @media (max-width: 479px) {
//     .intro {
//         .intro__form {
//             min-width: 300px;
//             max-width: 300px;
//         }

//         & .intro__inner {
//         	top: 17px;
//         }

//         .intro__form {
//         	& .tooltip_alt {
// 				display: none;
//         	}
        	
//         	& .form__title {
//         		font-size: 16px;
//         	}
        	
//         	& .form__body {
//         		& .form__row {
//         			margin-bottom: 10px;
//         		}
//         	}

//         	& .form__head {
//         		padding: 8px;
//         	}
//         }
//     }
// }


// @media(max-width: 320px){
//     .intro {
//         overflow: hidden;
//     }
// }