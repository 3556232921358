/* ------------------------------------------------------------ *\
	Shell
\* ------------------------------------------------------------ */

.shell { 
	margin: auto;
	max-width: $shell;
	padding: $shell-padding;
}

.shell:after { @extend .clearfix; }