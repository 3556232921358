/* ------------------------------------------------------------ *\
    Progress List
\* ------------------------------------------------------------ */

.form-steps {
	overflow: hidden;
	display: block;
	position: relative;
	margin: auto;
    max-width: 530px;
    font-weight: 700;

	
	&:after {
		content: "";
        display: table;
        clear: both;
	}

	&__item {
		padding: 0;
		position: relative;
		display: block;
		float: left;
		width: 33.33%;
		text-align: center;

        &:last-child .form-steps__item-line {
            display: none;
        }
	}

	&__item-content {
		// display: inline-block;
	}
	// Step Icon
	&__item-icon {
        background: white;
        color: $gray;
        display: block;
        border-radius: 100%;
        text-align: center;
        width: 33px;
        height: 33px;
        line-height: 30px;
        margin: 0 auto 10px auto;
        position: relative;
        font-size: 13px;
        font-weight: 700;
        z-index: 2;
        border: 2px solid $gray;
	}
	// Step text
	&__item-text {
		font-size: 12px;
		color: $gray;
	}

	&__item-line {
		display: inline-block;
		height: 3px;
		width: 100%;
		background: #cfd8dc;
		float: left;
		position: absolute;
		left: 50%;
		top: 12px;
		z-index: 1;
	}
	// Active Step
	&__item--active {
		.form-steps__item-icon {
			background-image: url("../css/images/car-step.jpg");
            background-repeat: no-repeat;
            width: 74px;
            height: 30px;
            border-radius: 0;
            border: none;
            color: $blue;
		}

		.form-steps__item-text {
			color: #acacac;
		}

		.form-steps__item-line {
			background: linear-gradient(to right, #2994e6 50%,#acacac 50%,#acacac 50%);
		}
	}
	// Active Step
	&__item--completed {
		.form-steps__item-text {
			color: $blue;
		}


		.form-steps__item-icon {
			background: $blue;
			color: $white;
			width: 33px;
			height: 33px;
			line-height: 33px;
            border: none;
		}

		.form-steps__item-line {
			background: $blue;
		}
	}
}
