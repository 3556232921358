/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */

.footer {
    background: $blue_alt;
    padding: 70px 0 30px;
    border-top: 2px solid $yellow;
    color: $white;

    & .footer__body {
        margin-bottom: 40px;
        overflow: hidden;
    }

    & .cols {
        margin: 0 -56px;
    }

    & .col {
        padding: 0 56px;
    }

    & .footer__map {
        padding: 0 27px;
    }

    & .footer__logo {
        display: block;;
        margin-bottom: 30px;
    }

    & .copyright {
        float: left;
    }

    & .credits {
        float: right;
        padding-right: 30px;
    }

    & .footer__line {
        font-size: 15px;
    }
}

@media (max-width:1023px) {
    .footer .footer__map {
        padding: 0;
    }
}

@media (max-width: 767px) {
    .footer {
        .footer__logo {
            text-align: center;
        }

        & .footer__article {
            text-align: center;
            margin-bottom: 30px;
        }
    }
}