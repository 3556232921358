/* ------------------------------------------------------------ *\
	Buttons
\* ------------------------------------------------------------ */

.btn { 
	display: inline-block;
	vertical-align: middle;
	background: $yellow;
	color: #16598c;
	text-transform: uppercase;
	font-weight: 500;
	border-radius: 2px;
	text-align: center;
	min-width: 293px;
	padding: 0 10px;
	height: 46px;
	line-height: 46px;
    transition: ease-in-out .3s;
    cursor: pointer;
    border: 0;

	&:hover {
		opacity: .7;
	}
}

.btn_alt {
    background: $blue;
    color: $white;

    &:hover {
        color: $blue_alt;
        background: $yellow;
        opacity: 1;
    }
}

.btn_gray {
    background: $light_gray;
    color: $white;
}

.btn_small {
	min-width: 205px;
}

.btn_grey {
	background: #8b8d97;
	color: $white;
}

.btn_block {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
}

.btn_medium {
    min-width: 353px;
}

.btn_large {
    min-width: 524px;
}

@media(max-width: 767px) {    
    .btn_large {
        min-width: 100%;
    }
}

@media(max-width: 479px){
    .btn {
        min-width: 80%; 
    }
}