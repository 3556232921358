/* ------------------------------------------------------------ *\
	Callout
\* ------------------------------------------------------------ */

.callout {
    text-align: center;

    & .callout__title {
        color: $blue;
        font-size: 40px;
        margin-bottom: 30px;
    }

    & .callout__body {
        margin-bottom: 30px;
    }

    & .callout__subtitle {
        font-size: 22px;
        margin-bottom: 55px;
        color: $blue;
        font-weight: 400;
    }
}

.callout_alt {
    display: flex;
    align-items: center;
    max-width: 650px;
    margin: auto;

    & .callout__image {
        flex: 1 1 50%;
    }

    & .callout__title {
        margin-bottom: 24px;
    }

    & .callout__article {
        margin-bottom: 24px;
    }
}

.callout_left {
    text-align: left;
    padding-right: 20px;

    & .callout__title {
        font-weight: 600;
        line-height: 1.25;
        margin-bottom: 25px;
    }

    & .callout__article {
        font-family: 'Open sans', sans-serif;
    }
}

@media(max-width: 767px) {
    .callout_alt {
        flex-direction: column;

        & .callout__image {
            margin-bottom: 20px;
        }
    }
}