/* ------------------------------------------------------------ *\
	Sprite
\* ------------------------------------------------------------ */

[class^="ico-"] {
	background-image: url(images/sprite.png);
	display: inline-block;
	vertical-align: middle;
}


.ico-login {
	background-position: -11px -10px;
	width: 26px;
	height: 22px;
}

.ico-calendar {
	background-position: -14px -46px;
	width: 20px;
	height: 20px;
}

.ico-calendar-alt {
    background-position: -19px -411px;
	width: 19px;
	height: 19px;
}

.ico-time {
	background-position: -60px -46px;
	width: 20px;
	height: 20px;
}

.ico-time-alt {
    background-position: -50px -410px;
	width: 20px;
	height: 20px;
}

.ico-visa {
    background-position: -11px -186px;
    width: 56px;
    height: 35px;
}

.ico-masterCard {
    background-position: -77px -186px;
    width: 56px;
    height: 35px;
}

.ico-amex {
    background-position: -142px -186px;
    width: 56px;
    height: 35px
}

.ico-paypal {
    background-position: -13px -238px;
	width: 51px;
	height: 32px;
} 

.ico-avatar {
    background-position: -56px -7px;
	width: 29px;
	height: 29px;
}

.ico-download {
	background-position:  -14px -293px; 
	width: 24px;
	height: 24px;
}

.ico-next {
	background-position: -48px -293px;
	width: 24px;
	height: 24px;
}

.ico-none {
  background-position: -91px -287px;
  width: 30px;
  height: 37px;
}

.ico-assuranc-voiture {
	background-position:-93px -329px;
	width: 28px;
	height: 35px;
}

.ico-lavage-exterior {
	background-position:  -230px -287px;
	width: 40px;
	height: 43px;;
}

.ico-lavage-exterior-interior {
	background-position: -180px -287px;
	width: 36px;
	height: 42px;
}

.ico-assurance-annulation {
	background-position: -135px -286px;
	width: 29px;
	height: 37px;
}

.ico-prise-en-charge {
	background-position: -139px -334px;
	width: 29px;
	height: 30px;
}

.ico-info {
  background-position: -104px -44px;
  width: 24px;
  height: 24px;
}