/* ------------------------------------------------------------ *\
	Type
\* ------------------------------------------------------------ */

.types {
	display: flex;
	align-items: center;

	& .type {
		flex: 1 1 50%;
	}

	& .typesInputs {
		display: none;
	}
}

.type {
	background: $blue;
	color: #fff;
	display: flex;
	align-items: center;
	margin-right: 25px;
	padding: 13px 7px;
	border-radius: 5px;
	position: relative;
	min-width: 530px;
	transition: ease-in-out .3s;

	&:hover {
		cursor: pointer;
	}

	&:last-child {
		margin-right: 0;
	}

	& .type__title {
		font-size: 20px;
		text-transform: uppercase;
		font-weight: 600;
		margin-right: 20px;
		min-width: 200px;
	}

	& img {
		margin-right: 5px;
	}

	& .type__info {
		font-size: 26px;
		position: absolute;
		top: 5px;
		right: 7px;
		line-height: 1;
	}

	&.active {
		background: $yellow;
	}
	
	& input {
		display: none;
	}
}



@media(max-width: 1200px) {
	.types {
		justify-content: center;
	}

	.type {
		min-width: initial;
		height: 88px;
		padding: 13px 30px;

		& .type__title {
			min-width: initial;
		}

		& p {
			font-size: 14px;
		}
	}
}

@media(max-width: 1023px) {
	.types {
		flex-direction: column;

		& .type {
			flex: 1;
			min-width: 100%;
		}
	}

	.type {
		margin: 0 0 20px;
		
		&:last-child {
			margin-bottom: 0;
		}
	}
}

@media(max-width: 479px) {
	.types {
		& .type {
			flex-direction: column;
			min-height: 160px;
		}
	}
}