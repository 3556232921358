/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */

.section {
	padding: 75px 0 50px;

	& .section__head {
		margin-bottom: 55px;
		text-align: center;

		& .section__detail {
			max-width: 615px;
			margin: auto;
			font-family: 'Open Sans', sans-serfi;
		}

		& .section__title {
			margin-bottom: 20px;
		}
	}
	& .section__body {
		position: relative;
		z-index: 1;
	}
}

.section__title {
	color: $blue;
}

.section__actions {
	text-align: center;
}

.section_background {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.section__title_alt {
	color: $white;
}

.section_0 {
	padding: 0;
}

.section_solid {
	background: #d3e5f4;
}

.section_solid-alt {
    background: #edeeef;

    .callout {
        margin-top: 80px;
    }

    .callout_left {
        margin-top: 0;
    }
}

.section_clouds {
	position: relative;
	&:after {
		content: '';
		background-image: url(images/clouds.png);
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100px;
	}
}

.section_alt {
    text-align: center;
    & .section__title {
        color: $white;
        line-height: 1.4;
        margin-bottom: 25px;
        z-index: 15;
        position: relative;;
    }
}

.section_background-alt {
    min-height: 330px;
    position: relative;
    display: flex;
    align-items: center;
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba($black,.18);
    }
}

.section_small {
    padding: 15px 0; 
}

.section_medium {
    padding: 50px 0;
}

.section_center {
    text-align: center;
}

.section_background-overlay {
    color: #ffffff;

    & .section__title {
        color: #fff;
        font-size: 65px;
        font-weight: 500;
        margin-bottom: 30px;
        text-shadow: 4px 0 8px #215781;
    }

    & .section__article-fixed  {
        width: 655px;
        margin: auto;
        font-size: 17px;
    }
}

.section_background-large {
    min-height: 890px;
    position: relative;
    overflow: hidden;

    &:after {
        content: url(images/temp/map_overlay.png);
        position: absolute;
        top: 0;
        left: 0;
    
    }

    & .section__head {
        text-align: left;
        position: relative;
        z-index: 1;

        &:after {
            content: '';
            display: block;
            clear: both;
        }
    }

    & .section__aside {
        width: 43%;
        float: left;
    }

    & .mapPin {
        float: right;
    }
}

@media(max-width: 767px) {
    .section_background-overlay {    
        & .section__article-fixed  {
            width: 100%;
        }
    }

    .section_background-large {
        & .section__aside {
            float: none;
            width: 100%;
        }
    }
}