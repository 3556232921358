/* ------------------------------------------------------------ *\
    Sociam
\* ------------------------------------------------------------ */

.contacts {
    font-weight: 500;

    & a {
        &:hover {
            color: $yellow;
        }
    }

    & li {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        transition: ease-in-out .3s;
        cursor: pointer;

        &:hover {
            color: $yellow;
        }
    }
    & .contacts__icon {
        width: 46px;
        height: 46px;
        background: yellow;
        text-align: center;
        font-size: 40px;
        border-radius: 4px;
        color: #2d647e;
        margin-right: 20px;
        flex: 0 0 46px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & .fa-map-marker {
        font-size: 36px;
    }

    & .fa-clock-o {
        font-size: 34px;
    }
}

@media(max-width: 767px) {
    .contacts {
        & li {
            justify-content: center;
        }

        & ul {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
        }
    }
}