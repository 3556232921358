/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */

.header_abs {
    position: absolute;
    width: 100%;
    z-index: 10;
}

.header {
	background: rgba($blue, .62);
    // rgba(41, 148, 230, 0.62)
	// background-image: url(images/header.png);
	background-size: cover;
	background-position: center;

	& .logo {
		float: left;
		position: relative;
		top: 14px;
	}

	& .nav {
		float: right;

		& a {
			color: $white;
		}
    }
    
    & .nav-lang {
        float: right;
    }
}

@media (max-width: 1023px) {
    .header {
        & .logo {
            left: 60px;
        }
    }
}

@media (max-width: 479px) {
    .header {
        & .logo {
            width: 50%;
            top: 14px;
        }
    }
}