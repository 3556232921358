/* ------------------------------------------------------------ *\
	Service
\* ------------------------------------------------------------ */

.services {
    margin: 0 -15px;
    counter-reset: my-badass-counter;
    
	&:after {
		@extend .clearfix;
	}

	.service {
		float: left;
		width: 25%;
		padding: 0 15px;
	}

	& + .section__actions {
		margin-top: 60px;
	}
}

.service {
	text-align: center;

	& .service__image {
        margin-bottom: 45px;
        min-height: 57px;
	}

	& .service__title {
		margin-bottom: 33px;
    }
    
    .service__content {
        // max-height: 170px;
        max-height: 100%;
        overflow: hidden;
    }
}

.service_alt  {
    position: relative;
        &:before {
            content: counter(my-badass-counter);
            counter-increment: my-badass-counter;
            position: absolute;
            left: 40px;
            top: -7px;
            color: #cfe2f8;
            font-size: 75px;
            font-weight: 900;
            font-family: 'Open Sans', sans-serif;
        }

    & .service__image {
        position: relative;
        width: 108px;
        height: 108px;
        background:$blue;
        line-height: 108px;
        border-radius: 5px;
        margin: auto auto 31px;
    }

    & .service__title {
        margin-bottom: 22px;
        text-align: center;
        line-height: 1.26;
    }

    & .service__content {
        max-height: 285px;
        overflow: hidden;
        text-align: justify;
        line-height: 1.625;
    }
}

@media (max-width: 1023px) {
    .services {    
        .service {
            float: left;
            width: 50%;
            padding: 0 15px;
        }    
    }
}

@media (max-width: 767px) {
    .services {
        .service {
            float: none;
            width: 100%;
            padding: 0 15px;
            margin-bottom: 40px;
        }    
    }

    .service {
        & .service__image {
            margin-bottom: 12px;
            min-height: initial;
        }

        & .service__title {
            margin-bottom: 12px;
        }
    }

    .services + .section__actions {
        margin-top: 0;
    }
}