/* ------------------------------------------------------------ *\
	Slider
\* ------------------------------------------------------------ */

.owl-dots {
	position: absolute;
	bottom: 30px;
	left: 50%;
	transform: translateX(-50%);
	align-items: center;
	display: flex;
}

.owl-dot  {
	width: 9px;
	height: 9px;
	border-radius: 50%;
	background: $white;
	margin-right: 10px;

	&.active {
		background: $yellow;
	}
}

.slider_intro {
    min-height: 672px;

	.slide__image {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		min-height: 672px;
	}
}

@media (max-width: 1160px) {
    .slider_intro {
        min-height: initial;
        .slide__image {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            min-height: none;
        }
    }
}

@media(max-width: 767px){
	.slider_intro {
	    min-height: 508px;
		.slide__image {
			min-height: 508px;
		}
	}

	.owl-dots {
		display: none;
	}
}