/* ------------------------------------------------------------ *\
    Accordion
\* ------------------------------------------------------------ */


.accordions {
    & .accordions__head {
        & .accordion__title {
            color: $blue;
            font-size: 26px;
            position: relative;
            padding-bottom: 10px;
            display: inline-block;;
    
            &:after {
                content: '';
                position: absolute;
                width: 105%;
                height: 1px;
                background: $blue;
                bottom: 0;
                left: 0;
            }
        }
    }
}

.accordion {
    padding: 6px 0;
    overflow:hidden;
    position:relative;
    width:960px;
}

.accordion__custom {
    text-align: left;
    padding-bottom: 18px;

    & .accordion__head {
        padding: 18px 30px 5px;
        position: relative;
    }

    & .accordion__title {
        margin-bottom: 0;
		cursor: pointer;
        display: inline-block;
        font-weight: 400;
        font-size: 22px;
        color: $dark_gray;
        text-align: left;
    }

    & .accordion__item {
        text-align: left;
    }

    & .accordion__body {
        display: none;
        padding: 16px 30px 16px 30px;
    }

    & a {
        color: inherit;
        display: block;
    }

    & li {
        color: $black;
    }

    & .current {
        color: $blue;
        background: #e6e8ed;
    }

    & .accordion__icon {
        position: absolute;
        top: 20px;
        right: 0;
        display: inline-block;
        margin-right: 16px;
    }

    & .accordion__icon.active {
        & span:nth-child(2) {
            transform: translate(-50%, -50%) rotate(90deg);
        }
    }
}

.accordion_alt {

    & .accordion__head {
        background: $blue;        
        padding: 10px 25px;
    }

    & .accordion__title {
        color: $white;
        text-transform: uppercase;
        font-size: 18px;
    }

    & .accordion__item {
        margin-bottom: 30px;
        
        &:last-child {
            margin-bottom: 0;
        }
    }
}

@media (max-width: 1023px) {
    .accordions { 
        & .accordions__head {
            & .accordion__title {
                display: block;
                text-align: center;

                &:after {
                    width: 100%;
                }
            }
        }
    }
    .accordions-wrapper {
        .col_1of2 {
            width: 100%;
        }
    }
}