/* ------------------------------------------------------------ *\
    Atoms
\* ------------------------------------------------------------ */
.p0 {
    padding: 0;
}
.pl30 {
    padding-left: 30px;
}

.pb30 {
    padding-bottom: 30px;
}

.pb20 {
    padding-bottom: 20px;
}

.pr30 {
    padding-right: 30px;
}

.pb50 {
    padding-bottom: 50px;
}

.mb10 {
    margin-bottom: 10px;
}

.mb45 {
    margin-bottom: 45px !important;
}
.mb30 {
    margin-bottom: 30px;
}
.mb50 {
    margin-bottom: 50px !important;
}

.tac {
    text-align: center;
}
.m0-15 {
    margin: 0 15px;
}
.w100p {
    width: 100%;
}
.mb20 {
    margin-bottom: 20px;
}
.mb35 {
    margin-bottom: 35px;
}
@media(max-width: 767px) {
    .pl30,
    .pr30 {
        padding: 0;
    }
}