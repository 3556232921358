/* ------------------------------------------------------------ *\
    InfoCar
\* ------------------------------------------------------------ */


#information {
    display: none;
}

.info_wrapper {
    text-align: center;
    margin-bottom: 45px;
}

.infos {
    justify-content: space-between;
    align-items: center;
    margin: 0 -15px;
    display: none;

    &:after {
        @extend .clearfix;
    }
    
    &.active {
        display: block;
    }

    & .info {
        float: left;
        width: 50%;
        padding: 0 15px;
        margin-bottom: 30px;
    }
}

.info {
    font-size: 17px;

    & .info__edit {
        position: absolute;
        right: 10px;
        top: 14px;
        cursor: pointer;
        transition: ease-in-out .3s;

        &:hover {
            color: $blue
        }
    }

    .info__inner {
        background: #f1f7fc;
        padding: 18px 55px 15px 25px;
        position: relative;
        border: 1px solid $blue; 
        
        &.active {
            background: #b6defb;
        }
    }

    & .info__row {
        border-bottom: 1px solid #a1cff3;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        padding-bottom: 7px;    

        & p {
            display: inline-block;
            width: 55%;
        }
    }
    
    & .info__model {
        color: $blue;
        position: relative;

        &.infoEditable {
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background: $yellow;
            }
        }
    }

    & .info__body {
        margin-bottom: 25px;
    }

    & .info__actions {
        text-align: center;
        position: relative;

        & .btn_icon {
            // position: absolute;
            // left: 50%;
            // transform: translateX(-50%);
            display: none;

            &.active {
                display: inline-block;
            }
        }
    }
}

.info_alt {
    & .info + .info {
        margin-top: 30px;
    }
}

.info_alt {
    display: block;
    flex-direction: column;
    margin-bottom: 15px;
} 