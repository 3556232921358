/* ------------------------------------------------------------ *\
    Testimonial
\* ------------------------------------------------------------ */

.testimonial {
	padding-top: 105px;
	font-size: 22px;
	color: #333333;
	position: relative;

	&:before {
		content: '';
		left: 0;
		top: 0;
		position: absolute;
		background: url('images/sprite.png') no-repeat -11px -81px;
		width: 107px;
		height: 76px;
	}

	& .testimonial__body {
		margin-bottom: 20px;

		& p {
			margin-bottom: 12px;
		}
	}

	& .testimonial__rating {
		color: $yellow;
	}

	& cite {
		font-weight: 300;
		font-style: normal;
		font-size: 20px;
	}
}

@media(max-width: 1150px) {
    .testimonial {
        text-align: center;

        &:before {
            left: 50%;
            transform: translateX(-50%);
        }
    }
}