/* ------------------------------------------------------------ *\
    Variables
\* ------------------------------------------------------------ */

/*  Colors  */
$white: #fff;
$black: #000;
$yellow: #ffcc00;
$blue: #2994e6;
$blue_alt: #1f3d54;
$dark_gray: #333333;
$orange: #ff5400; 
$red: #a94442;
$red_alt: #c30827;
$green: #3c763d;
$light_gray: #8b8d97;
$gray: #999999;
$gray_alt: #f1f1f1;

/*  Base body  */
$font-family: 'Ubuntu', sans-serif;
$font_second: 'Open Sans', sans-serif;
$font_third: 'Poppins', sans-serif;
$font-weight: 400;
$font-size: 16px;
$color: $dark_gray;
$line-height: 1.6;

/*  Links  */
$color-link: inherit;

/*  Shell  */
$shell: 1170px;
$shell-padding: 0 15px;  